import React, { useState, useEffect } from "react";
import classes from "./CustomEvent.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import wifiIcon from "../../images/wifi.svg";
import wifiOffIcon from "../../images/wifi-off.svg";
import freeicon from "../../images/free-icon.svg";
import { Box, FormControl, Typography } from "@mui/material";
import MyFormControl from "./MyFormControl";
import CustomRadioSelect from "./CustomRadioSelect";
import CustomMultiSelect from "./CustomMultiSelect";
import {
  ageGroupData,
  courtData,
  foodData,
  genderData,
  timeOptions,
} from "./CustomEvent";
import moment from "moment";

const LocalEventAccordion = (props) => {
  const {
    handleWheel,
    handleAddGroup,
    payment,
    groupDatas,
    groupName,
    addNewGroup,
    index,
    expanded,
    handleAccordionChange,
    parentFromTime,
    parentToTime,
  } = props;

  // const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [timeSlots, setTimeSlots] = useState([
    {
      court: "",
      fromTime: "",
      toTime: "",
    },
  ]);

  const [groupData, setGroupData] = useState({
    groupName: groupDatas?.groupName || "",
    gender: groupDatas?.gender || "",
    ageGroup: groupDatas?.ageGroup || [],
    amount: groupDatas?.amount || "",
    food: groupDatas?.food || [],
    payment: groupDatas?.payment || "",
    contactPerson: groupDatas?.contactPerson || "",
    contactNumber: groupDatas?.contactNumber || "",
    contactEmail: groupDatas?.contactEmail || "",
    noOfParticipants: groupDatas?.noOfParticipants || null,
    adminRemark: groupDatas?.adminRemark || "",
  });

  console.log("groupData", groupData);

  useEffect(() => {
    if (groupDatas?.timeSlots) {
      setTimeSlots(
        groupDatas.timeSlots
          .filter((slot) => slot.court)
          .map((slot) => ({
            court: slot.court,
            fromTime: slot.time.fromTime,
            toTime: slot.time.toTime,
          }))
      );
    } else {
      setTimeSlots([{ court: "", fromTime: "", toTime: "" }]);
    }
  }, [groupDatas]);

  const handleSave = (e) => {
    const newGroup = {
      ...groupData,
      timeSlots: timeSlots
        .filter((slot) => slot.court)
        .map((slot) => ({
          court: slot.court,
          time: {
            fromTime: slot.fromTime,
            toTime: slot.toTime,
          },
        })),
    };
    // setAccordionExpanded(false);
    handleAddGroup(e, newGroup, index);
    // addNewGroup();
    // handleAccordionChange(null);
  };

  const addNewTimeSlot = () => {
    setTimeSlots([...timeSlots, { court: "", fromTime: "", toTime: "" }]);
  };

  const handleTimeSlotChange = (index, field, value) => {
    setTimeSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];
      updatedSlots[index][field] = value;
      return updatedSlots;
    });
  };

  const calculateCourtHours = (from, to) => {
    const fromDate = new Date(`1970-01-01T${from}:00`);
    const toDate = new Date(`1970-01-01T${to}:00`);
    const diff = (toDate - fromDate) / 1000;
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);

    let result = "";
    if (hours > 0) result += `${hours} hour${hours > 1 ? "s" : ""} `;
    if (minutes > 0) result += `${minutes} minute${minutes > 1 ? "s" : ""}`;

    return result.trim() || "-";
  };

  // Time Slot execution
  const excludePreviousTimeRanges = (currentSlotIndex, court) => {
    let excludedTimes = [];

    for (let i = 0; i < currentSlotIndex; i++) {
      const slot = timeSlots[i];
      if (slot.court === court && slot.fromTime && slot.toTime) {
        const timeRange = timeOptions.filter((time) =>
          moment(time, "HH:mm").isBetween(
            moment(slot.fromTime, "HH:mm"),
            moment(slot.toTime, "HH:mm"),
            null,
            "[)"
          )
        );
        excludedTimes.push(...timeRange);
      }
    }

    return excludedTimes;
  };

  const filteredFromTimeOptions = (currentSlotIndex, court) => {
    if (!parentFromTime || !parentToTime) {
      return [];
    }
    const excludedTimes = excludePreviousTimeRanges(currentSlotIndex, court);
    const parentTimeRange = timeOptions.filter((time) =>
      moment(time, "HH:mm").isBetween(
        moment(parentFromTime, "HH:mm"),
        moment(parentToTime, "HH:mm"),
        null,
        "[]"
      )
    );

    if (!court) {
      return parentTimeRange.concat(parentToTime);
    }

    return parentTimeRange.filter((time) => {
      const currentSlot = timeSlots[currentSlotIndex];
      if (currentSlot && currentSlot.fromTime === time) {
        return true;
      }
      return !excludedTimes.includes(time);
    });
  };

  // Filter to Time
  const filterdToTimeOptions = (slot, currentSlotIndex, court) => {
    if (!parentFromTime || !parentToTime) {
      return [];
    }
    const excludedTimes = excludePreviousTimeRanges(currentSlotIndex, court);

    const parentTimeRange = timeOptions.filter((time) =>
      moment(time, "HH:mm").isBetween(
        moment(parentFromTime, "HH:mm"),
        moment(parentToTime, "HH:mm"),
        null,
        "[]"
      )
    );

    if (!court) {
      return parentTimeRange
        .filter((time) =>
          moment(time, "HH:mm").isAfter(moment(slot.fromTime, "HH:mm"))
        )
        .concat(parentToTime);
    }

    return parentTimeRange
      .filter((time) =>
        moment(time, "HH:mm").isAfter(moment(slot.fromTime, "HH:mm"))
      )
      .filter((time) => {
        if (slot.toTime === time) {
          return true;
        }
        return !excludedTimes.includes(time);
      });
  };

  return (
    <>
      <Accordion
        disableGutters={true}
        expanded={expanded}
        onChange={() => handleAccordionChange(index)}
        className="css-12v7fep-MuiPaper-root-MuiAccordion-root"
        sx={{
          borderRadius: 3,
          marginTop: 2,
          backgroundColor: "#FAFAFA",
          boxShadow: "none",
          border: 1,
          borderColor: "ButtonFace",
        }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          fontSize={13}
          // style={{
          //   borderBottom:"1px solid #0000000D"
          // }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              fontSize: {
                xs: "16px",
                sm: "20px",
                // md: "24px",
                // lg: "28px",
                // xl: "px",
              },
              fontFamily:"Poppins"
            }}
          >
            {groupData.groupName ? groupData.groupName : "New Group"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{marginTop:"-13px"}}>
            <form className="marginY">
              <formgroup
                controlId="exampleForm.ControlInput1"
                className={classes.namecontainer}
              >
                <label className={`${classes.labell} ${classes.changedLabel}`}>
                  Group Name
                </label>
                <input
                  className={`${classes.eventname} ${classes.control}`}
                  type="text"
                  placeholder="Enter Group name"
                  name="groupEventName"
                  id="groupEventInput"
                  onChange={(e) =>
                    setGroupData({ ...groupData, groupName: e.target.value })
                  }
                  value={groupData.groupName}
                  autoComplete="off"
                />
              </formgroup>
            </form>
            <div className={classes.typestatus}>
              <div className={classes.formcontainer}>
                <MyFormControl
                  title={"Gender"}
                  data={genderData}
                  value={groupData.gender}
                  setValue={(e) => setGroupData({ ...groupData, gender: e })}
                />
              </div>
              <div className={classes.formcontainer}>
                <CustomMultiSelect
                  title={"Age Group"}
                  data={ageGroupData}
                  value={groupData.ageGroup}
                  setValue={(e) => setGroupData({ ...groupData, ageGroup: e })}
                />
              </div>
            </div>
            <formgroup
              controlId="exampleForm.ControlInput1"
              className={classes.namecontainer}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                No. of pariticipants
              </label>
              <input
                className={`${classes.eventname} ${classes.control}`}
                type="text"
                placeholder="Enter number of participants"
                name="noOfParticipants"
                id="groupEventInput"
                onChange={(e) =>
                  setGroupData({
                    ...groupData,
                    noOfParticipants: e.target.value,
                  })
                }
                value={groupData.noOfParticipants}
                autoComplete="off"
              />
            </formgroup>

            {timeSlots.map((slot, index) => {
              return (
                <div key={index} className={classes.dateCourt}>
                  <formgroup className={classes.courtContainer}>
                    {/* Court Selection */}
                    <MyFormControl
                      title="Court"
                      data={courtData}
                      value={slot.court}
                      setValue={(value) =>
                        handleTimeSlotChange(index, "court", value)
                      }
                      placeholder="Select court"
                      disabled={!parentFromTime || !parentToTime}
                    />
                  </formgroup>

                  <formgroup
                    className={classes.timeWidth}
                    controlId="exampleForm.ControlInput1"
                  >
                    {/* Time Label with Court Hours Calculation */}
                    <label
                      className={`${classes.labell} ${classes.changedLabel}`}
                    >
                      {`Time ${
                        slot.fromTime && slot.toTime
                          ? `(${calculateCourtHours(
                              slot.fromTime,
                              slot.toTime
                            )})`
                          : ""
                      }`}
                    </label>

                    {/* From and To Time Selection */}
                    <div className={classes.timelmntcontainer}>
                      {/* From Time */}
                      <MyFormControl
                        title=""
                        data={filteredFromTimeOptions(index, slot.court)}
                        value={slot.fromTime}
                        setValue={(value) =>
                          handleTimeSlotChange(index, "fromTime", value)
                        }
                        placeholder="Select Time"
                        disabled={!parentFromTime || !parentToTime}
                      />

                      {/* To Time */}
                      <MyFormControl
                        title=""
                        data={filterdToTimeOptions(slot, index, slot.court)}
                        value={slot.toTime}
                        setValue={(value) =>
                          handleTimeSlotChange(index, "toTime", value)
                        }
                        placeholder="Select Time"
                        disabled={
                          !slot.fromTime || !parentFromTime || !parentToTime
                        }
                      />
                    </div>
                  </formgroup>
                </div>
              );
            })}

            <button className={classes.btnAddGroup} onClick={addNewTimeSlot}>
              + Add New
            </button>

            <div className="flexTop paddingBottom">
              <formgroup
                controlId="participantcontainer"
                className={classes.amount}
              >
                <label className={`${classes.labell} ${classes.changedLabel}`}>
                  Amount
                </label>
                <input
                  required
                  type="number"
                  name="amount"
                  placeholder="Enter amount"
                  className={classes.control}
                  onChange={(e) =>
                    setGroupData({ ...groupData, amount: e.target.value })
                  }
                  value={groupData.amount}
                  autoComplete="off"
                  onWheel={handleWheel}
                />
              </formgroup>

              <formgroup style={{ width: "50%" }}>
                <CustomMultiSelect
                  id="groupFood"
                  title={"Food"}
                  data={foodData}
                  value={groupData.food}
                  setValue={(e) => setGroupData({ ...groupData, food: e })}
                />
              </formgroup>
            </div>

            <FormControl
              className={`${classes.eventformat} ${classes.paymentstatuscontainer}`}
            >
              <label className={`${classes.labell} ${classes.changedLabel}`}>
                Payment
              </label>
              <div className={classes.radioselectcontainer}>
                <CustomRadioSelect
                  rotateRight
                  img={wifiIcon}
                  values={"Online"}
                  setValue={() =>
                    setGroupData({ ...groupData, payment: "Online" })
                  }
                  styleclass={groupData.payment === "Online" ? "active" : ""}
                />
                <CustomRadioSelect
                  img={wifiOffIcon}
                  values={"Offline"}
                  setValue={() =>
                    setGroupData({ ...groupData, payment: "Offline" })
                  }
                  styleclass={groupData.payment === "Offline" ? "active" : ""}
                />
                <CustomRadioSelect
                  img={freeicon}
                  values={"Free"}
                  setValue={() =>
                    setGroupData({ ...groupData, payment: "Free" })
                  }
                  styleclass={groupData.payment === "Free" ? "active" : ""}
                />
              </div>
            </FormControl>

            {/** contact details */}

            <div className={classes.amountcontainer}>
              <formgroup
                controlId="participantcontainer"
                className={classes.amount}
              >
                <label className={`${classes.labell} ${classes.changedLabel}`}>
                  Contact Person
                </label>
                <input
                  required
                  type="text"
                  name="groupContactPerson"
                  placeholder="Enter person name"
                  className={classes.control}
                  onChange={(e) =>
                    setGroupData({
                      ...groupData,
                      contactPerson: e.target.value,
                    })
                  }
                  value={groupData.contactPerson}
                  autoComplete="off"
                />
              </formgroup>
              <formgroup
                controlId="participantcontainer"
                className={classes.amount}
              >
                <label className={`${classes.labell} ${classes.changedLabel}`}>
                  Contact Number
                </label>
                <input
                  required
                  type="number"
                  name="groupContactNumber"
                  placeholder="Enter contact number"
                  className={classes.control}
                  onChange={(e) =>
                    setGroupData({
                      ...groupData,
                      contactNumber: e.target.value,
                    })
                  }
                  value={groupData.contactNumber}
                  autoComplete="off"
                  onWheel={handleWheel}
                />
              </formgroup>
            </div>
            <div className={classes.emailcontainer}>
              <formgroup
                controlId="participantcontainer"
                className={classes.email}
              >
                <label className={`${classes.labell} ${classes.changedLabel}`}>
                  Contact Email Id
                </label>
                <input
                  required
                  type="email"
                  name="groupContactEmail"
                  placeholder="Enter email id"
                  className={classes.control}
                  onChange={(e) =>
                    setGroupData({ ...groupData, contactEmail: e.target.value })
                  }
                  value={groupData.contactEmail}
                  autoComplete="off"
                />
              </formgroup>
              <formgroup
                controlId="participantcontainer"
                className={classes.email}
              >
                <label
                  className={`${classes.labell} ${classes.changedLabel} mt-3`}
                >
                  Remark
                </label>
                <textarea
                  type="text"
                  name="groupContactEmail"
                  placeholder="Enter remark here"
                  className={classes.control}
                  onChange={(e) =>
                    setGroupData({ ...groupData, adminRemark: e.target.value })
                  }
                  value={groupData.adminRemark}
                  autoComplete="off"
                />
              </formgroup>
            </div>
            <button
              className={classes.btnsubmit}
              onClick={(e) => handleSave(e)}
            >
              Save
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LocalEventAccordion;
